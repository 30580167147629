const config = {
  gatsby: {
    pathPrefix: '/',
    siteUrl: 'https://docs.connect.cosoft.co.uk',
    gaTrackingId: null,
    trailingSlash: false,
  },
  header: {
    logo: 'https://cosoft.co.uk/images/connect-white.svg',
    logoLink: 'https://cosoft.co.uk/software/connect',
    title: '',
    githubUrl: '',
    helpUrl: '',
    tweetText: '',
    social: ``,
    links: [{ text: '', link: '' }],
    search: {
      enabled: true,
      indexName: 'docs',
      algoliaAppId: process.env.GATSBY_ALGOLIA_APP_ID,
      algoliaSearchKey: process.env.GATSBY_ALGOLIA_SEARCH_KEY,
      algoliaAdminKey: process.env.ALGOLIA_ADMIN_KEY,
    },
  },
  sidebar: {
    forcedNavOrder: [
      '/getting-started', // add trailing slash if enabled above
      'calls',
      'contacts',
      'calendar',
      'apps',
      'settings',
    ],
    collapsedNav: [
      '/settings', // add trailing slash if enabled above
      '/apps/whatsapp',
      '/sidekick',
    ],
    links: [
      { text: 'Home', link: 'https://cosoft.co.uk/software/connect' },
      { text: 'Login', link: 'https://connect.cosoft.co.uk' },
    ],
    frontLine: false,
    ignoreIndex: true,
    title: '',
  },
  siteMetadata: {
    title: 'Connect | Docs',
    description: 'Connect | Docs',
    ogImage: null,
    docsLocation: '',
    favicon: 'https://cosoft.co.uk/favicon-32x32.png',
  },
  pwa: {
    enabled: false, // disabling this will also remove the existing service worker.
    manifest: {
      name: 'Connect Documentation', // Gatsby Gitbook Starter
      short_name: 'Connect Docs',
      start_url: '/',
      background_color: '#6b37bf',
      theme_color: '#6b37bf',
      display: 'standalone',
      crossOrigin: 'use-credentials',
      icons: [
        {
          src: 'src/pwa-512.png',
          sizes: `512x512`,
          type: `image/png`,
        },
      ],
    },
  },
};

module.exports = config;
